import { RouteRecordRaw } from "vue-router";

import { UserRole } from "../api/models/user";

const routes: RouteRecordRaw[] = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/cadastro",
    name: "Cadastro",
    component: () => import("../views/user/SignUp.vue"),
  },
  {
    path: "/",
    component: () => import("../components/LayoutNavbarHorizontalAndSearch.vue"),
    children: [
      {
        path: "/home",
        name: "Uncomplicated intranet.",
        component: () => import("../views/user/Home.vue"),
        meta: { requiresAuth: true, priority: 0 },
      },
      {
        path: "/all-content",
        name: "All Content",
        component: () => import("../views/user/AllContent.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/content/:id",
        name: "Content",
        component: () => import("../views/user/ContentDetails.vue"),
        meta: { requiresAuth: true, breadcrumb: [] },
      },
      {
        path: "/news/:id?",
        name: "News",
        component: () => import("../views/user/news/News.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/scheduled-news",
        name: "Scheduled News",
        component: () => import("../views/user/news/NewsScheduled.vue"),
        meta: {
          requiresAuth: true,
          allowedUserRoles: [UserRole.SUPER_ADMINISTRATOR, UserRole.ADMINISTRATOR],
        },
      },
      {
        path: "/news-editor/:id",
        name: "News editor",
        component: () => import("../views/user/news/NewsEditor.vue"),
        meta: {
          requiresAuth: true,
          allowedUserRoles: [UserRole.SUPER_ADMINISTRATOR, UserRole.ADMINISTRATOR],
        },
      },
      {
        path: "/my-space/:id?",
        name: "My space",
        component: () => import("../views/user/Sublibrary.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/libraries",
        name: "Libraries",
        component: () => import("../views/user/CompanyLibraries.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/library/:id",
        name: "Library",
        component: () => import("../views/user/Sublibrary.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/company-deleted-content",
        name: "Company Deleted Content",
        component: () => import("../views/user/ContentTrashBinTable.vue"),
        props: { type: "company" },
        meta: {
          requiresAuth: true,
          allowedUserRoles: [UserRole.SUPER_ADMINISTRATOR, UserRole.ADMINISTRATOR],
        },
      },
      {
        path: "/personal-deleted-content",
        name: "Personal Deleted Content",
        component: () => import("../views/user/ContentTrashBinTable.vue"),
        props: { type: "personal" },
        meta: { requiresAuth: true },
      },
      {
        path: "/people",
        name: "People",
        component: () => import("../views/user/People.vue"),
        meta: {
          requiresAuth: true,
          allowedUserRoles: [UserRole.SUPER_ADMINISTRATOR, UserRole.ADMINISTRATOR],
        },
      },
      {
        path: "/people-deactivated",
        name: "People Deactivated",
        component: () => import("../views/user/PeopleDeactivated.vue"),
        meta: {
          requiresAuth: true,
          allowedUserRoles: [UserRole.SUPER_ADMINISTRATOR, UserRole.ADMINISTRATOR],
        },
      },
      {
        path: "/analytics/:kind?",
        name: "Analytics",
        component: () => import("../views/user/Analytics.vue"),
        meta: {
          requiresAuth: true,
          allowedUserRoles: [UserRole.SUPER_ADMINISTRATOR, UserRole.ADMINISTRATOR],
        },
      },
      {
        path: "/help",
        name: "Help",
        component: () => import("../views/user/Help.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/terms-and-policies",
        name: "Terms and policies",
        component: () => import("../views/user/TermsAndPolicies.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/policy/:id?",
        name: "Policy",
        component: () => import("../views/user/Policy.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/platform-settings",
        name: "Platform settings",
        component: () => import("../views/user/PlatformSettings.vue"),
        meta: {
          requiresAuth: true,
          allowedUserRoles: [UserRole.SUPER_ADMINISTRATOR],
        },
      },
      {
        path: "/contact-kala",
        name: "Contact Kala",
        component: () => import("../views/user/PlatformContact.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/search",
        name: "Search",
        component: () => import("../views/user/SearchAI.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/share/:id",
        name: "Share",
        component: () => import("../views/Share.vue"),
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: "/exemplos",
    component: () => import("../components/LayoutNavbarVertical.vue"),
    children: [
      {
        path: "icones",
        name: "Ícones",
        component: () => import("../views/user/examples/_Icon.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "botoes",
        name: "Botões",
        component: () => import("../views/user/examples/_Button.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "toast",
        name: "Toast",
        component: () => import("../views/user/examples/_Toast.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "modal",
        name: "Modal",
        component: () => import("../views/user/examples/_Modal.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "datas",
        name: "Datas",
        component: () => import("../views/user/examples/_Date.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "numeros",
        name: "Números",
        component: () => import("../views/user/examples/_Number.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "selecao",
        name: "Seleção",
        component: () => import("../views/user/examples/_Select.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "inputs-de-texto",
        name: "Inputs de texto",
        component: () => import("../views/user/examples/_InputText.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "inputs-variados",
        name: "Inputs variados",
        component: () => import("../views/user/examples/_InputVarious.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "compra-segura",
        name: "Compra segura",
        component: () => import("../views/user/examples/_CreditCardPurchase.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "collapse",
        name: "Collapse",
        component: () => import("../views/user/examples/_Collapse.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "skeleton",
        name: "Skeletons",
        component: () => import("../views/user/examples/_Skeleton.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "paginacao",
        name: "Paginação",
        component: () => import("../views/user/examples/_Pagination.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "tabelas",
        name: "Tabelas",
        component: () => import("../views/user/examples/_Table.vue"),
        meta: { requiresAuth: true },
      },
    ],
  },
];

export default routes;
